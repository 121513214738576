import * as React from "react"
import Logo from "../logo"
import {
  StyledHeader,
  StyledMenuSection,
  StyledMenuContainer,
  StyledHeaderText,
} from "./styled-components"
import { IconButton } from "../buttons"

export default props => (
  <StyledHeader>
    <StyledMenuContainer>
      <StyledMenuSection>
        <Logo />
        <StyledHeaderText>Work in progress</StyledHeaderText>
      </StyledMenuSection>
      <IconButton
        icon={props.isDarkMode ? "sun" : "contrast"}
        setIsDarkMode={props.setIsDarkMode}
        isDarkMode={props.isDarkMode}
      />
    </StyledMenuContainer>
  </StyledHeader>
)
