import React from "react"
import "./src/styles/global.css"
import "./src/styles/bootstrap-grid.css"
import Layout from './src/components/layout'
import { typography } from './src/utils/typography'

typography.injectStyles()

export const wrapPageElement = ({ element, props }) => (
  <Layout {...props}>{element}</Layout>
);

