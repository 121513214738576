import styled from "styled-components"

export const StyledHeader = styled.header`
  display: flex;
  flex-direction: row;
  padding: 22px 0px;
`

export const StyledMenuSection = styled.div`
  display: flex;
  flex: 1 0 auto;
  align-self: ${props => (props.alignSelf ? props.alignSelf : "start")};
`

export const StyledMenuContainer = styled.div`
  width: 100%;
  padding-right: 22px;
  padding-left: 22px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: row;

  @media (min-width: 1100px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 900px;
  }

  @media (min-width: 1400px) {
    max-width: 1200px;
  }
`

export const StyledHeaderText = styled.span`
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-left: 20px;
  font-weight: 600;
  color: ${props => props.theme.colors.textSecondary};
`
